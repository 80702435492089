@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}
